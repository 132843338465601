import { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import styles from "../../styles/Firstconnexion.module.scss";
import { globalKeys, validatePassword } from "../../utils/utils";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { GeneralContext } from "../../contexts/GeneralContext";

export default function SetupPassword ({id, email, token, afterPasswordChanged, userRole, resetPassword = false}) {
    const PUBLIC_BACK_URL = process.env.REACT_APP_NUVEE_BASE_URL.split('/api')[0];

    const [password, setPassword] = useState("");
    const [passwordValidate, setPasswordValidate] = useState("");
    const [cguAccept, setCguAccept] = useState(false);

    const [passwordHasError, setPasswordHasError] = useState(false);
    const [passwordValidateHasError, setPasswordValidateHasError] = useState(false);
    const [passwordCheckOK, setPasswordCheckOK] = useState(false);
    const [passwordValidateCheckOK, setPasswordValidateCheckOK] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showPassValidation, setshowPassValidation] = useState(false);
    const generalDetails = useContext(GeneralContext);

    const [cguPdf, setCguPdf] = useState(null);
    const [charterPdf, setCharterPdf] = useState(null);
  
    const onPasswordChange = (event) => setPassword(event.target.value);
    const onPasswordValidationChange = (event) => setPasswordValidate(event.target.value);
    const showPasswordHandler = () => setShowPass(!showPass);
    const showPasswordValidationHandler = () => setshowPassValidation(!showPassValidation);
    const onCguAcceptChange = (event) => setCguAccept(event.target.checked);

    useEffect(() => {
      if (userRole && !resetPassword) {
        let populateAgreement = "";
        let fieldsList = "";

        switch (userRole) {
          case globalKeys.patient:
            populateAgreement += "populate[0]=cgupdf";
            populateAgreement += "&populate[1]=charterpdf";

            fieldsList = "fields[0]=cgupdf";
            fieldsList += "&fields[1]=charterpdf";
            break;
          case globalKeys.pro_equipe:
          case globalKeys.pro_etablissement:
          case globalKeys.pro_payeur:
          case globalKeys.pro_standard:
          case globalKeys.phenotypage:
            populateAgreement += "&populate[0]=cgupropdf";
            fieldsList += "&fields[0]=cgupropdf";
            break;
          case globalKeys.patient_prevention:
            populateAgreement += "populate[0]=cgupdf";
            fieldsList += "fields[0]=cgupdf";
            break;
          default:
            break;
        }

        axios
          .get(
            process.env.REACT_APP_NUVEE_BASE_URL +
              `/agreementsdatum?${populateAgreement}&${fieldsList}`
          )
          .then((response) => {
            const { cgupdf, charterpdf, cgupropdf } = response.data;
            if (cgupdf?.url) {
              setCguPdf(cgupdf.url);
            } else if (cgupropdf?.url) {
              setCguPdf(cgupropdf.url);
            }
            if (charterpdf?.url) {
              setCharterPdf(charterpdf.url);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }, [userRole]);

    useEffect(() => {
        if (password !== "") {
          setPasswordHasError(!validatePassword(password));
          setPasswordCheckOK(validatePassword(password));
          setPasswordValidateHasError(password !== passwordValidate);
          setPasswordValidateCheckOK(!(password !== passwordValidate));
        }
    }, [password, passwordValidate]);
    
    const handleValidatePassword = () => {
        if (!passwordCheckOK || !passwordValidateCheckOK) {
          generalDetails.setAlert({
            show: true,
            message: !passwordCheckOK  ? "Le mot de passe renseigné n'est pas valide." : "Les mots de passe ne correspondent pas entre eux.",
            header: "Erreur de saisie",
            dismissible: true,
            variant: "danger",
            timout: 5000,
          });
          return false;
        }
        return true;
    };
    
    const onSubmit = async (event) => {
        event.preventDefault();
        if (!handleValidatePassword()) {
          return;
        }
        updatePassword();
    };
    
    const updatePassword = () => {
        const argsChangePassword = {
            id: id,
            email: email,
            token: token,
            password: password,
            ...(!resetPassword && cguAccept && cguPdf && {
              cgu: cguAccept,
              cguaccepted: new Date()
            }),
            ...(!resetPassword && cguAccept && charterPdf && {
              forumCharter: cguAccept
            })
        };
        generalDetails.setShowSpinner(true);
        axios.post(process.env.REACT_APP_NUVEE_BASE_URL + '/users-permissions/setFirstPassword', argsChangePassword)
            .then(() => {
                generalDetails.setAlert({
                    show: true,
                    message: "Le mot de passe est validé",
                    header: "",
                    dismissible: true,
                    variant: "success",
                    timout: 5000,
                });
                if(afterPasswordChanged) {
                    afterPasswordChanged(password);
                }
            })
            .catch((error) => {
                console.error(error?.message);
                generalDetails.setAlert({
                    show: true,
                    message: "Une erreur est survenue lors du changement de mot de passe",
                    header: "Erreur",
                    dismissible: true,
                    variant: "danger",
                    timout: 5000,
                });
            })
            .finally(() => {
              generalDetails.setShowSpinner(false);
            });
    };
    
    return (
      <Form className={`w-50 ${styles.loginForm}`} onSubmit={onSubmit}>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Mot de passe</Form.Label>
          <InputGroup>
            <Form.Control
              type={showPass ? "text" : "password"}
              onChange={onPasswordChange}
              value={password}
              className={`${
                passwordHasError
                  ? styles.error
                  : passwordCheckOK
                  ? styles.success
                  : ""
              }`}
            />
            <InputGroup.Text onClick={showPasswordHandler}>
              {showPass ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Form.Group controlId="formBasicPasswordValidation" className="mt-3">
          <Form.Label>Validation du mot de passe</Form.Label>
          <InputGroup>
            <Form.Control
              type={showPassValidation ? "text" : "password"}
              onChange={onPasswordValidationChange}
              value={passwordValidate}
              className={`${
                passwordValidateHasError
                  ? styles.error
                  : passwordValidateCheckOK
                  ? styles.success
                  : ""
              }`}
            />
            <InputGroup.Text onClick={showPasswordValidationHandler}>
              {showPassValidation ? (
                <FontAwesomeIcon icon={faEyeSlash}/>
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </InputGroup.Text>
          </InputGroup>
          <Form.Text
            className={
              passwordCheckOK || password === ""
                ? styles.passwordHintHide
                : styles.passwordHintShow
            }
          >
            Votre mot de passe doit satisfaire aux conditions suivantes :
            <ul>
              <li>minimum 7 caract&egrave;res</li>
              <li>maximum 20 caract&egrave;res</li>
              <li>au moins une majuscule</li>
              <li>au moins une minuscule</li>
              <li>au moins un chiffre</li>
              <li>pas d&apos;espace</li>
            </ul>
          </Form.Text>
          {((passwordCheckOK && password !== "" && passwordValidate !== "") && password !== passwordValidate) && (
            <Form.Text className={styles.passwordHintError}>
              Les mots de passe ne correspondent pas entre eux.
            </Form.Text>
          )}
        </Form.Group>

        {(cguPdf || charterPdf) && (
          <Form.Group controlId="formCGUValidation" className="mt-3">
            <Form.Check
              type="checkbox"
              id="validationCGU"
              label={
                <div className={styles.cguLabel}>
                  En cochant cette case, je déclare avoir pris connaissance
                  des&nbsp;
                  <a href={`${PUBLIC_BACK_URL}${cguPdf}`} target="_blank">
                    CGU
                  </a>
                  {charterPdf && (
                    <>
                      &nbsp;et de la&nbsp;
                      <a
                        href={`${PUBLIC_BACK_URL}${charterPdf}`}
                        target="_blank"
                      >
                        charte du forum
                      </a>
                    </>
                  )}
                  &nbsp; du site Nuvee et je les accepte
                </div>
              }
              onChange={onCguAcceptChange}
              value={cguAccept}
            />
          </Form.Group>
        )}

        <Button
          variant="primary"
          className={`my-3 ${styles.sendButton}`}
          type="submit"
          disabled={(cguPdf || charterPdf) && !cguAccept}
        >
          VALIDER
        </Button>
      </Form>
    );
};